import * as React from 'react'
import styled from 'styled-components'

const MenuContainer = styled.div`
  position: relative;
`

const Menu = styled.nav<{ isActive?: boolean }>`
  background: var(--pages-header-menuBackgroundColor);
  border-radius: 6px;
  padding: 10px 16px 20px 10px;
  position: absolute;
  top: 30px;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1;

  ${(props) =>
    props.isActive &&
    `
    opacity: 1;
    visibility: visible;
    transform: translateY(0);`}

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
`

const MenuTrigger = styled.div`
  background: transparent;
  border: none;
  color: var(--pages-header-menuTextColor);
`

const StyledArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--pages-header-menuBackgroundColor);
  position: absolute;
  top: -8px;
  right: 12px;
`

const DropdownMenu = ({ title, children }) => {
  const dropdownRef = React.useRef(null)
  const [isActive, setIsActive] = React.useState(false)

  const onClick = () => setIsActive(!isActive)

  React.useEffect(() => {
    const pageClickEvent = (e: Event) => {
      // If the active element exists and is clicked outside of
      if (
        dropdownRef.current !== null &&
        !dropdownRef.current.contains(e.target)
      ) {
        setIsActive(!isActive)
      }
    }

    // If the item is active (ie open) then listen for clicks
    if (isActive) {
      window.addEventListener('click', pageClickEvent)
    }

    return () => {
      window.removeEventListener('click', pageClickEvent)
    }
  }, [isActive])

  return (
    <MenuContainer ref={dropdownRef}>
      <MenuTrigger onClick={onClick} className="menu-trigger">
        {title}
      </MenuTrigger>
      <Menu isActive={isActive}>
        <StyledArrow />
        <ul>{children}</ul>
      </Menu>
    </MenuContainer>
  )
}

export default DropdownMenu
