import { FormikErrors } from 'formik'
import styled, { css } from 'styled-components'
import { StyledCard } from '../layouts/styles'
import TextareaAutosize from 'react-textarea-autosize'

const StyledTitleContainer = styled.div`
  border-left: 2px solid var(--cards-sectionTitle-lineColor);
  color: var(--cards-sectionTitle-textColor);
  background: var(--cards-sectionTitle-backgroundColor);
  margin-bottom: 20px;
  padding-right: 15px;
  border-radius: 0px 10px 10px 0px;
`

const StyledGroupContainer = styled.div`
  ${StyledTitleContainer} {
    margin: 0;
    margin-top: 20px;

    @media (max-width: 500px) {
      margin-top: 20px;
    }
  }

  &:first-of-type {
    ${StyledTitleContainer} {
      margin-top: 0;
    }
  }
`

const StyledTitle = styled.div`
  display: flex;
  align-items: center;

  span {
    padding: 0 13px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    margin-top: 10px;
  }

  hr {
    margin: 0;
    margin-top: 10px;
    padding: 0 13px;
  }
`
const StyledDivider = styled.hr`
  flex-grow: 1;
  color: var(--elements-notificationPanel-textColor);
  border: none;
  border-top: 1px solid var(--elements-notificationPanel-textColor);
  margin: 24px 0;

  @media (max-width: 500px) {
    display: none;
  }
`
const StyledDesc = styled.div`
  padding: 8px 13px 8px 13px;
  font-size: 12px;
`

const StyledOptionsCard = styled(StyledCard)`
  padding: 24px;

  > hr:not(:last-of-type) {
    display: none;
  }

  > div:not(:last-of-type) > hr {
    display: none;
  }
`
const StyledFieldLabel = styled.label`
  font-size: 14px;
  line-height: 16px;
  color: var(--pages-mainContainer-primaryTextColor);
  margin: 16px 0 0;
  display: block;
  flex-grow: 1;
`

const StyledBaseField = css<{
  error: string | FormikErrors<any> | string[] | FormikErrors<any>[]
}>`
  border: 1px solid #969999;
  margin-top: 8px;
  padding: 10px;
  color: var(--pages-mainContainer-primaryTextColor);
  font-size: 14px;
  line-height: 15px;
  border-radius: 4px;
  box-sizing: border-box;
  flex: 1;
  outline-color: var(--pages-mainContainer-primaryTextColor);

  ${({ error, theme }) =>
    error
      ? css`
          border: 1px solid ${theme.colors.error};
        `
      : ``}

  &:not([type='checkbox']) {
    @media (max-width: 500px) {
      width: 100%;
    }
    @media (min-width: 501px) {
      min-width: 250px;
    }
  }

  &:read-only {
    background: #f7f7f7;
  }

  &:focus {
    border: 1px solid var(--pages-mainContainer-primaryTextColor);
  }
`

const StyledFieldInput = styled.input`
  ${StyledBaseField}
`

const StyledAutosizeTextarea = styled(TextareaAutosize)`
  resize: none;
  ${StyledBaseField}
`

const StyledFieldDesc = styled.div`
  margin-top: 4px;
  color: var(--pages-mainContainer-primaryTextColor);
  font-size: 11px;
  line-height: 16px;
  opacity: 60%;
`

const StyledDPWrapper = styled.div`
  display: flex;
  align-items: center;

  ${StyledFieldInput} {
    background: var(--pages-mainContainer-backgroundColor);
  }

  svg {
    margin-left: 13px;
    cursor: pointer;
  }
`
const StyledSelectContainer = styled.div`
  position: relative;
  width: 250px;

  &:not([type='checkbox']) {
    @media (max-width: 500px) {
      width: 100%;
    }
    @media (min-width: 501px) {
      min-width: 250px;
    }
  }

  svg {
    position: absolute;
    right: 8px;
    top: 8px;
    transform: rotate(-90deg);
    pointer-events: none;
  }
`

const StyledFieldSelect = styled.select`
  ${StyledBaseField}
  width: 100%;
  margin: 16px 0 8px;
  background: var(--pages-mainContainer-backgroundColor);
  cursor: pointer;
  min-height: max-content;
  height: max-content;
  white-space: normal;
  display: grid;
  padding-right: 30px;

  &:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  &:read-only {
    background: var(--pages-mainContainer-backgroundColor);
  }

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`

const StyledFieldError = styled.div`
  color: ${(props) => props.theme.colors.error};
  font-size: 11px;
  font-style: italic;
  display: inline-block;
  margin-left: 16px;

  @media (max-width: 500px) {
    margin: 0;
  }
`

const StyledFieldContainer = styled.div`
  display: block;
  width: fit-content;

  &.hidden {
    display: none;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  .react-datepicker-wrapper {
    width: auto;
    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .react-datepicker__tab-loop {
    display: inline-block;
  }
`

const StyledSelectFieldContainer = styled(StyledFieldContainer)`
  width: auto;
`

const StyledTextTitle = styled(StyledFieldLabel)`
  font-size: 14px;
  line-height: 16px;
  color: var(--pages-mainContainer-primaryTextColor);
  font-weight: 400;
`

const StyledTextDesc = styled(StyledFieldDesc)`
  font-size: 11px;
  line-height: 16px;
  margin-top: 4px;
  color: var(--pages-mainContainer-primaryTextColor);
  font-weight: 400;
`

export {
  StyledTitleContainer,
  StyledFieldInput,
  StyledAutosizeTextarea,
  StyledFieldLabel,
  StyledFieldDesc,
  StyledTitle,
  StyledOptionsCard,
  StyledDesc,
  StyledDivider,
  StyledDPWrapper,
  StyledFieldError,
  StyledGroupContainer,
  StyledFieldSelect,
  StyledFieldContainer,
  StyledTextTitle,
  StyledTextDesc,
  StyledBaseField,
  StyledSelectContainer,
  StyledSelectFieldContainer,
}
