import React from 'react'
import { useField, useFormikContext } from 'formik'
import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import { StyledFieldInput, StyledDPWrapper } from '../styles'
import { ReactComponent as CalendarIcon } from '../../theme/icons/calendar.svg'
import 'react-datepicker/dist/react-datepicker.css'
import { formatDate } from '../../helpers/form'
import actionCodes from '../../context/actionCodes'
import { Portal } from 'react-overlays'

const StyledIcon = styled(CalendarIcon)`
  margin-top: 8px;
`

const CustomInput = React.forwardRef((props, ref) => {
  //Only calendar available

  return (
    <StyledDPWrapper>
      {/* @ts-ignore */}
      <StyledFieldInput ref={ref} className="test" {...props} readOnly />
      {/* @ts-ignore */}
      <StyledIcon onClick={props.onClick} />
    </StyledDPWrapper>
  )
})

const CalendarContainer = ({ children }) => {
  const el = document.getElementById('calendar-portal')

  return <Portal container={el}>{children}</Portal>
}

const DatePickerField = (props) => {
  const {
    setFieldValue,
    //setFieldTouched
  } = useFormikContext()
  const [field] = useField(props)

  const {
    updateCode,
    value,
    dateType,
    code,
    minDate,
    maxDate,
    format,
    onBlur,
    caseId,
    ...rest
  } = props

  const td = new Date()
  const yes = new Date(td)
  yes.setDate(yes.getDate() - 1)

  const tom = new Date(td)
  tom.setDate(tom.getDate() + 1)

  // https://exuscouk.atlassian.net/browse/CSS-85
  // "today": true means that you should be allowed to select today's date
  // "pastDate": true means that you should be allowed to select a date in the past
  // "futureDate": true means that you should be allowed to select a date in the future
  const valDates = {
    dateFormat: format || 'dd/MM/yyyy',
  }

  // TODO remove dateFormat hardcoded value
  // if (format) {
  //   valDates['dateFormat'] = format || 'dd/MM/yyyy'
  // }

  if (dateType === undefined || dateType === '6') {
    //PTP form or Custom datetype
    if (minDate) {
      valDates['minDate'] = new Date(actionCodes.replaceValue(caseId, minDate))
    }
    if (maxDate) {
      valDates['maxDate'] = new Date(actionCodes.replaceValue(caseId, maxDate))
    }
  } else {
    // We no longer receive these booleans from backend. Instead we
    // introduced the EFS modes ( 0-5 ) plus a custom one ( 6 ) with min and
    // max value. If dateType exists in array the corresponding boolean becomes true
    const today = ['0', '1', '3', '5'].includes(dateType)
    const pastDate = ['0', '2', '3'].includes(dateType)
    const futureDate = ['0', '4', '5'].includes(dateType)

    //Dataforms
    if (!futureDate) {
      if (!today) {
        valDates['maxDate'] = yes
      } else {
        valDates['maxDate'] = td
      }
    }

    if (!pastDate) {
      if (!today) {
        valDates['minDate'] = tom
      } else {
        valDates['minDate'] = td
      }
    }

    if (today && !futureDate && !pastDate) {
      valDates['maxDate'] = td
      valDates['minDate'] = td
    }
  }

  let selected = null

  if (value) {
    if (typeof value === 'object') {
      selected = value
    } else {
      selected = formatDate(value)
    }
  }

  return (
    <DatePicker
      {...rest}
      {...valDates}
      selected={selected}
      popperContainer={CalendarContainer}
      onChange={(val: Date) => {
        // Datepicker returns local timezone
        // TODO temporary fix. we should revisit this
        val.setHours(23, 59, 59)
        //setFieldTouched(field.name)
        setFieldValue(field.name, val)
        updateCode(caseId, code, field.name, val.toISOString(), true)
        onBlur({
          target: {
            name: rest.name,
            type: 'datepicker',
            value: val,
          },
        })
      }}
      customInput={<CustomInput {...rest} />}
    />
  )
}

export default DatePickerField
